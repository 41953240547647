<template>
  <div
    id="app"
    :class="{ 'fixed-page': $route.name == 'Sales' && $route.params.tab == 'sales' }"
  >
    <not-found v-if="$route.name === '404'" />
    <v-app v-else-if="$route.name" :dark="$vuetify.theme.dark">
      <login v-if="$route.path === '/' || $route.path === '/login'" />
      <logout v-if="$route.name === 'Logout'" />
      <authenticated-content
        v-else-if="$route.name !== 'Login' && $route.name !== 'Logout'"
      />
    </v-app>
    <div v-else style="min-height: 100vh">
      <v-app>
        <loading-page>Connecting to app...</loading-page>
      </v-app>
    </div>
  </div>
</template>

<script>
import AuthenticatedContent from "./AuthenticatedContent"
import LoadingPage from "./LoadingPage"
import Login from "./Login"
import Logout from "./Logout"
import NotFound from "./NotFound"
import fixInputBlur from "@/mixins/fixInputBlur.js"

export default {
  name: "app",
  components: {
    AuthenticatedContent,
    Login,
    Logout,
    LoadingPage,
    NotFound,
  },
  mixins: [fixInputBlur],
  mounted() {
    const darkMode =
      window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    if (darkMode) {
      this.$vuetify.theme.dark = true
    }

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", e => {
        this.$vuetify.theme.dark = e.matches
      })

    this.fixInputBlur()
  },
}
</script>

<style lang="scss">
.fixed-page {
  max-height: 100vh;
  overflow: hidden;
}
.border-top {
  border-top: 1px solid var(--v-light-border-base);
}
.border-bottom {
  border-bottom: 1px solid var(--v-light-border-base);
}
.text--highlight {
  background: #ffd60059;
  flex: none !important;
  padding: 3px;
  margin-top: -3px;
  margin-bottom: -3px;
}
.text--lighttext {
  color: var(--v-lighttext-base) !important;
}
.theme--dark.v-application,
.theme--dark.v-card:not(.cards-card):not(.transparent):not([class*="-background"]),
.theme--dark.v-tabs-items:not(.transparent):not([class*="-background"]) {
  background: var(--v-background-base) !important;
}
.theme--dark.v-data-table {
  .v-expansion-panels .v-expansion-panel,
  .v-card,
  .v-alert.v-alert--border-left {
    background-color: var(--v-light-background-lighten2) !important;
  }
}
.v-tabs {
  .v-tabs-bar__content {
    border-bottom: solid 1px var(--v-primary-base) !important;
    .v-tab:not(.v-tab--active) {
      border-bottom: none !important;
    }
  }
  .v-slide-group__prev.v-slide-group__prev--disabled {
    display: none;
  }
}
.v-alert__icon.v-icon {
  align-self: center;
}
// Actually instead of working on the PRs, can you work on the NextEats app
.page-title {
  text-transform: uppercase;
  font-weight: 100;
  font-size: 1.8em;
  line-height: 0.8em;
}
.v-dialog .theme--dark.v-card {
  background: var(--v-background-lighten1) !important;
  border: solid 1px var(--v-background-base) !important;
  .v-card {
    background: var(--v-background-lighten2) !important;
  }
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-width: 1000px;
}
.v-tabs-bar {
  .v-tab:not(.v-tab--active) {
    border-bottom: solid 1px;
  }
  .v-tab.v-tab--active {
    border-bottom: solid 3px;
  }
}
.v-tabs-bar .v-tab:not(.v-tab--active) {
  border-bottom: solid 1px;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(180, 180, 180, 0.1);
}
.v-data-table {
  thead {
    background-color: var(--v-primary-base) !important;
    color: var(--v-text-on-primary-bg-base) !important;
    th {
      color: var(--v-text-on-primary-bg-base) !important;
      padding-top: 8px !important;
      padding-bottom: 3px !important;
      span {
        white-space: pre-line;
        display: flex;
      }
    }
  }
  .text-center span {
    justify-content: center;
  }
}
.cards-card {
  background: var(--v-light-background-base) !important;
  border: solid 1px var(--v-light-border-base) !important;
  padding-top: 1em;
  padding-bottom: 1em;
}
.table-card {
  color: var(--v-text-on-primary-bg-base) !important;
  .v-data-table thead {
    background-color: var(--v-primary-base) !important;
    * {
      color: var(--v-text-on-primary-bg-base) !important;
    }
  }
  > *:not(.v-data-table),
  > *:not(.v-data-table) * {
    color: var(--v-text-on-primary-bg-base) !important;
  }
  input {
    caret-color: var(--v-text-on-primary-bg-base);
  }
}
fieldset {
  border-radius: 5px;
  border: 1px solid var(--v-secondary-lighten3);
}
.v-input fieldset {
  border-color: var(--v-light-border-base) !important;
}
.v-input.error--text .v-input__slot > fieldset {
  border-color: red !important;
}
.fixed-btn-container {
  display: block !important;
  .fixed-btn {
    position: relative;
    float: right;
  }
}
.fixed-btn {
  position: fixed;
  right: 15px;
  z-index: 999;
}
.red-select .v-select__selection,
.red-select .v-select__selection--comma,
.red-select .v-select.v-text-field input,
.red-select fieldset {
  color: red !important;
  border-color: red !important;
}
.green-select .v-select__selection,
.green-select .v-select__selection--comma,
.green-select .v-select.v-text-field input,
.green-select fieldset {
  color: #4caf50 !important;
  border-color: #4caf50 !important;
}
.orange-select .v-select__selection,
.orange-select .v-select__selection--comma,
.orange-select .v-select.v-text-field input,
.orange-select fieldset {
  color: orange !important;
  border-color: orange !important;
}
.grey-select .v-select__selection,
.grey-select .v-select__selection--comma,
.grey-select .v-select.v-text-field input,
.grey-select fieldset {
  color: grey !important;
  border-color: grey !important;
}
.has-11-columns {
  // Note: Need to add :class="{ 'has-11-columns': $vuetify.breakpoint.mdAndUp }" to a given v-row
  .col-2 {
    flex: 0 0 18.18181818%;
    max-width: 18.18181818%;
  }
  .col-1 {
    flex: 0 0 9.09090909%;
    max-width: 9.09090909%;
  }
}
.header_style,
.table-card .v-data-table-header,
.table-card .v-data-table-header * {
  background-color: var(--v-primary-base) !important;
  color: #fff !important;
}
.table-card > div:first-child:not(.v-data-table) * {
  border: none !important;
  color: #fff !important;
  .v-input {
    background-color: var(--v-primary-base) !important;
    padding: 0 !important;
    border-bottom: none;
  }
}
.table-card .v-data-table-header i,
.v-autocomplete__content {
  z-index: 202 !important;
}
.amount-input {
  width: 80px;
}
/* Hide up down arrows on number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.apexcharts-yaxis text,
.apexcharts-xaxis text,
.apexcharts-legend-text {
  color: var(--v-text-base) !important;
  fill: var(--v-text-base) !important;
}
</style>
