const formErrors = $v => {
  const errors = []
  if (!$v.$dirty) return errors

  const errorMessages = {
    required: "This field is required.",
    numeric: "Value must be numeric.",
    maxLength: "Value must be less than %max% digits",
  }

  const fields = Object.keys($v.$params)
  fields.forEach(field => {
    errors[field] = []
    const rules = Object.keys($v[field].$params)
    rules.forEach(rule => {
      if (!$v[field][rule]) {
        let err = errorMessages[rule]
        if (err) {
          // Here we replace any template variables with the appropriate parameters
          // (adopted from https://stackoverflow.com/a/378001)
          err = err.replace(/%(\w*)%/g, function (m, key) {
            const ruleParams = $v[field].$params[rule]
            return ruleParams.hasOwnProperty(key) ? ruleParams[key] : ""
          })
          errors[field].push(err)
        }
      }
    })
  })
  return errors
}

export { formErrors }
