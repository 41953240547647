<template>
  <v-navigation-drawer
    v-model="drawer"
    :color="`${$vuetify.theme.dark ? 'primary darken-4' : ''}`"
    :width="200"
    app
    clipped
    :permanent="drawer && !['xs'].includes($vuetify.breakpoint.name)"
  >
    <v-list
      expand
      :class="!['xs'].includes($vuetify.breakpoint.name) ? 'pt-0 mt-n1' : 'pt-13'"
    >
      <v-list-item-group v-model="currentPage">
        <div v-for="(item, i) in menuItems" :key="i">
          <v-list-item
            v-if="!item.subLinks"
            :to="item.route"
            :active-class="color"
            avatar
            class="v-list-item"
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon>mdi-{{ sublink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <div v-else>
            <v-divider v-if="i > 0" />
            <v-list-item disabled>
              <p class="mt-3 mb-0 text-uppercase" style="letter-spacing: 4px">
                {{ item.title }}
              </p>
            </v-list-item>
            <v-list-item
              v-for="sublink in item.subLinks"
              :key="item.title + sublink.title"
              :to="sublink.route"
              active-class="highlighted"
              :class="
                (item.route === $route.path ? 'highlighted' : '') +
                (sublink.icon ? '' : 'ml-10')
              "
            >
              <v-list-item-icon v-if="sublink.icon" class="mr-4">
                <v-icon>mdi-{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.title }}</v-list-item-title>
            </v-list-item>
          </div>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  name: "side-nav",
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentPage: this.$route.path,
    }
  },
  computed: {
    ...mapState(["currentUserRole", "restaurants"]),
    ...mapGetters(["getSettings", "isNextGenUser"]),
    settings() {
      return this.getSettings
    },
    menuPages() {
      return {
        "inventory-items": {
          title: "Inventory Items",
          route: "/inventory",
          icon: "tray-full",
        },
        "variance-report": {
          title: "Variance Report",
          route: "/varianceReport",
          icon: "chart-areaspline",
        },
        "supplier-items": {
          title: "Supplier Items",
          route: "/supplierItems/items",
          icon: "playlist-edit",
        },
        menus: { title: "Menus", route: "/menu/menus", icon: "food" },
        "menu-items": {
          title: "Menu Items",
          route: "/menu/items",
          icon: "hamburger",
        },
        "modifier-lists": {
          title: "Modifier Lists",
          route: "/modifiers/lists",
          icon: "menu",
        },
        modifiers: {
          title: "Modifier Items",
          route: "/modifiers/items",
          icon: "note-edit-outline",
        },
        orders: {
          title: "Today's Orders",
          route: "/sales/orders",
          icon: "point-of-sale",
        },
        "scheduled-orders": {
          title: "Scheduled Orders",
          route: "/sales/scheduled",
          icon: "calendar-clock",
        },
        "past-orders": {
          title: "Past Orders",
          route: "/sales/history",
          icon: "history",
        },
        "sales-report": {
          title: "Sales Report",
          route: this.getFinancialReportRoute,
          icon: "currency-usd",
          management: true,
        },
        "sales-analytics": {
          title: "Sales Analytics",
          route: "/reporting/analytics",
          icon: "chart-bar",
          management: true,
        },
        "orders-report": {
          title: "Advanced Report",
          route: "/reporting/report",
          icon: "chart-line",
          management: true,
        },
        "purchase-orders": {
          title: "Order Supplies",
          route: "/purchase/AddEditForm",
          icon: "send",
        },
        "receive-inventory": {
          title: "Receive Inventory",
          route: "/receive/AddEditForm",
          icon: "truck-delivery",
        },
        "inventory-count": {
          title: "Count Inventory",
          route: "/count/AddEditForm",
          icon: "basket-check",
        },
        writeoff: {
          title: "Write-Off Inventory",
          route: "/writeoff/AddEditForm",
          icon: "notebook-edit-outline",
        },
        coupons: {
          title: "Coupons",
          route: "/coupons",
          icon: "ticket-percent-outline",
          management: true,
        },
        promotions: {
          title: "Promotions",
          route: "/promotions",
          icon: "bullhorn-outline",
          management: true,
        },
        customers: {
          title: "Customers",
          route: "/customers",
          icon: "account-group-outline",
          management: true,
        },
      }
    },
    menuItems() {
      let filteredMenuItems = []
      let menuGroups = {
        sales: {
          title: "Sales",
          subLinks: [
            this.menuPages["orders"],
            this.menuPages["scheduled-orders"],
            this.menuPages["past-orders"],
          ],
        },
        menuModifiers: {
          title: "Menu",
          subLinks: [this.menuPages["menu-items"], this.menuPages["modifiers"]],
        },
        menu: {
          title: "Menu",
          subLinks: [this.menuPages["menu-items"], this.menuPages["menus"]],
        },
        modifiers: {
          title: "Modifiers",
          subLinks: [this.menuPages["modifiers"], this.menuPages["modifier-lists"]],
        },
        inventory: {
          title: "Inventory",
          subLinks: [
            this.menuPages["inventory-items"],
            this.menuPages["supplier-items"],
          ],
        },
        reporting: {
          title: "Reporting",
          subLinks: [
            ...[
              // this.menuPages["orders-report"],
            ],
            // ...(this.settings?.inventoryEnabled
            //   ? [this.menuPages["variance-report"]]
            //   : []),
          ],
        },
        operations: {
          title: "Operations",
          subLinks: [
            this.menuPages["purchase-orders"],
            this.menuPages["receive-inventory"],
            this.menuPages["inventory-count"],
            this.menuPages["writeoff"],
          ],
        },
        marketing: {
          title: "Marketing",
          subLinks: [
            this.menuPages["coupons"],
            // this.menuPages["promotions"],
            this.menuPages["customers"],
          ],
        },
      }

      const reportMenuPage = this.menuPages["sales-report"]
      if (this.isNextGenUser) {
        reportMenuPage.route = "/reporting/nextgen"
        menuGroups.reporting.subLinks.push(reportMenuPage)
      } else if (this.currentUserRole === "Admin") {
        reportMenuPage.route = "/reporting/org"
        menuGroups.reporting.subLinks.push(reportMenuPage)
      } else if (this.restaurants.length) {
        const restaurantId = this.restaurants[0].id
        reportMenuPage.route = `/reporting/restaurant/${restaurantId}`
        menuGroups.reporting.subLinks.push(reportMenuPage)
      }
      menuGroups.reporting.subLinks.push(this.menuPages["sales-analytics"])

      if (this.settings?.inventoryEnabled)
        filteredMenuItems = [
          menuGroups.sales,
          menuGroups.menuModifiers,
          menuGroups.reporting,
          menuGroups.marketing,
          menuGroups.inventory,
          menuGroups.operations,
        ]
      else
        filteredMenuItems = [
          menuGroups.sales,
          menuGroups.menu,
          menuGroups.modifiers,
          menuGroups.reporting,
          menuGroups.marketing,
        ]
      return filteredMenuItems.filter(item => {
        if (item.subLinks) {
          item.subLinks = item.subLinks.filter(sublink => {
            if (sublink.management) {
              return (
                this.currentUserRole === "Manager" ||
                this.currentUserRole === "Admin"
              )
            } else {
              return true
            }
          })
          return item.subLinks.length > 0
        } else {
          return true
        }
      })
    },
    getFinancialReportRoute() {
      return this.isNextGenUser
        ? "/reporting/nextgen"
        : this.currentUserRole === "Admin"
        ? "/reporting/org"
        : "/reporting/restaurant/" + this.restaurants[0].id
    },
  },
}
</script>
