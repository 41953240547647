import Vue from "vue"
import App from "./App.vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
import "@mdi/font/css/materialdesignicons.css"
import Vuelidate from "vuelidate"
import { formErrors } from "@/helpers"

Vue.use(Vuelidate)
import router from "./routes/index.js"
import { store } from "./store/index"
import themes from "./themes.js"
import ActionButton from "./AuthenticatedContent/shared/ActionButton.vue"
import moment from "moment"

import { createVPhoneInput } from "v-phone-input"
import "v-phone-input/dist/v-phone-input.css"
import "flag-icons/css/flag-icons.min.css"

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
})

Vue.component("nxg-action-btn", ActionButton)

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(Vuetify)
Vue.use(vPhoneInput)

Vue.filter("currency", function (value) {
  if (typeof value !== "number") {
    return value
  }
  var formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  })
  return formatter.format(value)
})

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: themes.black,
  },
})

Vue.mixin({
  methods: {
    getFormErrors: (vuelidate, fieldName) => {
      return formErrors(vuelidate)[fieldName]
    },
  },
})

Vue.prototype.moment = moment

Vue.config.devtools = true
Vue.config.performance = true

new Vue({
  router: router,
  vuetify: vuetify,
  store,
  render: h => h(App),
}).$mount("#app")
