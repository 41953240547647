<template>
  <v-container
    class="fill-height d-flex align-center justify-center"
    style="place-content: center"
  >
    <v-row class="align-self-end">
      <v-col class="d-flex justify-center">
        <img
          id="loading-graphic"
          :src="getLogoImg()"
          :style="
            `max-height: 30px;` + ($vuetify.theme.dark ? `` : `filter: invert(1)`)
          "
        />
      </v-col>
    </v-row>
    <v-row class="align-self-start">
      <v-col class="d-flex justify-center">
        <v-progress-linear
          indeterminate
          style="max-width: 110px"
          :color="$vuetify.theme.dark ? 'white' : 'black'"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row class="align-self-start">
      <v-col class="d-flex justify-center text--lighttext">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "loading-page",
  methods: {
    getLogoImg() {
      const logoPath =
        window.location.hostname === "admin.nexteats.ca"
          ? "logos/nexteats.webp"
          : "logo.svg"
      return require("@/assets/" + logoPath)
    },
  },
}
</script>

<style lang="scss" scoped>
#loading-graphic {
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
  }
} /* all other browsers */
@keyframes spinner {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}
</style>
